<template>
  <div>
    <div class="header">Permission</div>
  </div>
</template>
<script>
export default {}
</script>
<style scoped>
.header {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  float: left;
}
</style>